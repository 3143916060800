@import 'common/variables';

.lr-fixed-button {
  position: fixed;
  padding: 1rem ;
  left: 0.5rem;
  right: 0.5rem;
  background: $white;
  bottom: 0;
  z-index: 20;
  &__buttons {
    margin: 0 auto;
    width: 46.5%;
    text-align: center;
  }
  // Min devices (desktops, 992px and down)
  @media (max-width: 992px) {
    & {
      &__buttons {
        width: 100%;
      }
      height: auto;
      left: 1.538rem;
      right: 1.538rem;
      transform: none;
    }
  }

  @media (min-width: 1200px) {
    & {
      padding-left: 3rem;
      padding-right: 4.5rem;
    }
  }


}

