// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1400px) {
  .container-fluid {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}

.col-xl-2.5 {
  flex: 0 0 25%;
  max-width: 25%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.page-content {
  padding: 5rem 1rem 1rem;

  flex-grow: 1;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}


.page-loader {
  overflow: hidden;
}