@import 'common/variables';

.lr-page-loader {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__box {
    display: block;
    width: 12.5rem;
    height: 12.5rem;
    margin: -6.25rem 0 0 -6.25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;

    &__line {
      border-radius: 50%;
      border-left: 4px solid;
      border-right: 4px solid;
      border-top: 4px solid transparent !important;
      border-bottom: 4px solid transparent !important;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;

      &__one {
        border-color: $primary;
        width: 7.5rem;
        height: 7.5rem;
        margin: -3.75rem 0 0 -3.75rem;
      }

      &__two {
        border-color: $secondary;
        width: 6.25rem;
        height: 6.25rem;
        margin: -3.125rem 0 0 -3.125rem;
        animation-delay: 0.2s;
      }

      &__three {
        border-color: $blue;
        width: 5rem;
        height: 5rem;
        margin: -2.5rem 0 0 -2.5rem;
        animation-delay: 0.4s;
      }

      &__four {
        border-color: $gray;
        width: 3.75rem;
        height: 3.75rem;
        margin: -1.875rem 0 0 -1.875rem;
        animation-delay: 0.6s;
      }
    }

  }



  @-webkit-keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0%   {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
