@import 'common/variables';

.lr-input-textarea {
  .form-control {
    height: auto;
    resize: none;
    padding: 0.625rem 1rem;
    border-radius: .5rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    border: 0.063rem solid $border-color;
  }

  .form-control.has-danger {
    border-color: $red;
    color: $red;
  }

}
