@import 'common/variables';

.lr-button {
  display: inline-block;

  .btn-neutral {
    opacity: 1 !important;
    cursor: unset !important;
    
    &:hover {
      opacity: 1 !important;
    }
  }

  &> .btn {
    display: inline-block;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border: none;
    padding: 0.913rem 1.25rem;
    min-height: 3.125rem;
    width: max-content;
    font-style: normal;
    font-weight: 600;
    font-size: 0.813rem;
    line-height: 1.188rem;
    border-radius: 0.5rem;
  }

  .btn-sm {
    padding: .313rem .625rem;
    min-height: 1.875rem;
  }

  .btn-white {
    color: $gray;
    border: .063rem solid $border-color;
    font-weight: 500;

    img {
      padding-left: .5rem;
      vertical-align: sub;
    }

    &:hover {
      color: $gray;
    }
  }

  .btn-white-no-border {
    color: $gray-1;
    border: unset;
    font-weight: 500;
    padding: 0;

    &:hover {
      color: $gray-1;
    }
  }

  .btn-subscribe {
    color: $primary;
    background: $white;
    border-radius: 0.5rem;
    width: 100%;

    &:hover {
      color: $primary;
    }
  }

  .btn-primary {
    color: $white;
    background-color: $primary;

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      color: $white;
      background-color: $primary;
    }

    &:not(:disabled):not(.disabled):active {
      color: $white;
      background-color: $primary;
      border: none ;
    }

    &:active {
      color: $white;
    }

    &:hover {
      color: $white;
      background-color: $primary;
      box-shadow: none ;
    }

    &:focus {
      color: $white;
      background-color: $primary;
      box-shadow: none ;
    }
  }

  .btn-secondary {
    color: $primary;
    background-color: $secondary;

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
      color: $primary;
    }

    &:not(:disabled):not(.disabled):active {
      background-color: $secondary;
      border: none ;
    }

    &:disabled {
      color: $primary;
      background-color: $secondary;
    }

    &:active {
      color: $primary;
    }

    &:hover {
      color: $primary;
      background-color: $secondary;
    }

    &:focus {
      color: $primary;
      background-color: $secondary;
      border: none;
      box-shadow: none;
    }
  }

  @media(max-width: 544px){
    & {
      display: block;
      width: 100%;

      &> .btn {
        display: block;
        width: 100%;
      }
    }
  }

}
