.display-1 {
  font-style: $display1-font-style;
  line-height: $display1-line-height;
  color: $display1-color;
  @media screen and (max-width: 680px) {
    & {
      font-size: calc(20px + (36 - 20) * ((100vw - 320px) / 680));
    }
  }
}

.display-2 {
  font-style: $display2-font-style;
  line-height: $display2-line-height;
  color: $display2-color;
  word-break: break-word;
}

.display-3 {
  font-style: $display3-font-style;
  line-height: $display3-line-height;
  color: $display3-color;
  word-break: break-word;
}

.display-4 {
  font-style: $display4-font-style;
  line-height: $display4-line-height;
  color: $display4-color;
  word-break: break-word;
}

.text-blue {
  color: $blue;
}
