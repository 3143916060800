$primary: #02AFA4 !default;
// $secondary: #fed88b !default;
$secondary: #f3e787 !default;
$success: #02AFA4 !default;
$danger: #f9004b !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;

$blue: #0B254B !default;
$gray: #6D7C93 !default;
$gray-1: #B0B2B4 !default;
$gray-2: #F8F8FA !default;
$gray-3: #EBECF0 !default;
$gray-4: #F8F8FA !default;
$red: #f9004b !default;
$white: #fff !default;

$theme-colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "gray": $gray,
      "blue": $blue ),
    $theme-colors
);

$link-hover-color: $primary !default;

$border-color: #E0E2E4 !default;
$navbar-border-color: rgba(11, 37, 75, 0.04);
$navbar-color: #37b4aa;

$headings-font-family: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 2.25rem !default;
$display2-size: 1.5rem !default;
$display3-size: 1.063rem !default;
$display4-size: .875rem !default;

$display1-line-height: 3.375rem !default;
$display2-line-height: 2rem !default;
$display3-line-height: 1.875rem !default;
$display4-line-height: 1.313rem !default;

$display1-color: $blue !default;
$display2-color: $blue !default;
$display3-color: $gray !default;
$display4-color: $gray !default;

$display1-font-style: normal !default;
$display2-font-style: normal !default;
$display3-font-style: normal !default;
$display4-font-style: normal !default;

$display1-weight: 600 !default;
$display2-weight: 600 !default;
$display3-weight: normal !default;
$display4-weight: normal !default;
$display-line-height: $headings-line-height !default;

$font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-primary: "Poppins";
$font-secondary: "Miriam Libre";

$font-family-base: $font-primary !default;

$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;

$enable-grid-classes: true;
$enable-print-styles: true;

$input-box-shadow: none !default;

$input-focus-bg: none !default;
$input-focus-border-color: none !default;
$input-focus-color: none !default;
$input-focus-width: null !default;
$input-focus-box-shadow: none !default;

$btn-focus-box-shadow: 0;
$btn-disabled-opacity: .65 !default;
$btn-active-box-shadow: 0;
$btn-box-shadow: 0;

$spacer: 1rem !default;
$spacers: (
  ) !default;

$spacers: map-merge((0: 0,
      1: ($spacer * .25),
      2: ($spacer * .625),
      3: $spacer,
      4: ($spacer * 2),
      5: ($spacer * 3)),
    $spacers
);

$headings-margin-bottom: 0rem !default;
$paragraph-margin-bottom: 0rem !default;

$link-hover-color: null !default;
$link-hover-decoration: null !default;
$modal-shadow: 0px 0.25rem 0.75rem rgba(11, 37, 75, 0.04);

$pagination-border: #ddd !default;
$pagination-hover-border: null !default;
$pagination-active-bg: $primary !default;
$pagination-active-border: null !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: null !default;

$navbar-light-color: $gray !default;
$navbar-light-hover-color: rgba($gray, .5) !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($primary, .3) !default;
$navbar-light-toggler-border-color: rgba($primary, .1) !default;

$dropdown-bg: $white !default;
$dropdown-border: $border-color !default;
$dropdown-fallback-border: #ccc !default;
$dropdown-divider-bg: #e5e5e5 !default;

$dropdown-link-color: $gray !default;
$dropdown-link-hover-color: rgba($gray, .5) !default;
$dropdown-link-hover-bg: $white !default;

$dropdown-link-active-color: $primary !default;
$dropdown-link-active-bg: $white !default;

$badge-font-size: 0.813rem !default;
$badge-font-weight: 500 !default;
$badge-padding-y: .313rem !default;
$badge-padding-x: 0.625rem !default;
$badge-border-radius: 0.25rem !default;