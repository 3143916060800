@import 'common/variables';

.lr-form-input {

  margin-bottom: 1rem;

  &__password {
    position: absolute;
    right: 1.125rem;
    top: 0.8rem;
    z-index: 10;
    cursor: pointer;
  }

  .form-control:focus {
    background-color: $white;
    border-color: $border-color;
    outline: 0;
    box-shadow: none;
  }

  .form-control[readonly] {
    background-color: $white;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .input-icon {
    height: 1.125rem;
    width: 1.125rem;
  }

  .input-group-text {
    padding: 0.8rem 1rem;
    background-color: $white;
    border: 0.063rem solid $border-color;
    border-radius: 0.5rem;
  }

  .form-control.has-danger, .has-danger.input-group > .input-group-prepend > .input-group-text {
    border-color: $red;
    color: $red;

    .input-group-text {
      border-color: $red;
    }
  }

  .form-control {
    padding: 0.313rem 1rem;
    border-radius: .5rem;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    height: 3.125rem;
    border: 0.063rem solid $border-color;
  }

  .input-group > .form-control:not(:first-child) {
    border-left: none;
  }

  .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }
}
