@import 'common/variables';

.lr-danger-msg {
  font-style: normal;
  font-weight: normal;
  font-size: 0.688rem;
  line-height: 1.563rem;
  padding-left: 0.2rem;
  color: $red;
}

