@import 'common/variables';

.lr-control-label {
  font-style: normal;
  font-weight: 500;
  font-size: .9rem;
  line-height: 1.5rem;

  color: $blue;
}

